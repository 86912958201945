import { useSafeState } from "core/hooks";
import { isAuctionSuccess } from "core/model/auctions";
import { Auction } from "core/types";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
} from "react";
import { Outlet } from "react-router-dom";

export type CandidateContext = {
  candidates: number | null | undefined;
  patientId?: number;
};

type SearchCandidatesContextType = CandidateContext & {
  hasCandidates?: boolean;
  setCandidates: Dispatch<SetStateAction<CandidateContext>>;
};
const defaultSearchCandidatesContext = {
  candidates: null,
  patientId: undefined,
  hasCandidates: undefined,
  setCandidates: () => {},
};

const SearchCandidatesContext = createContext<SearchCandidatesContextType>(
  defaultSearchCandidatesContext,
);

export function shouldDisplayCandidates(auction: Auction | undefined): boolean {
  return (auction && !isAuctionSuccess(auction.status)) || false;
}

export function useCandidates() {
  return useContext(SearchCandidatesContext);
}

export function SearchCandidatesProvider({
  children,
  defaultCandidates,
}: {
  children?: ReactNode;
  defaultCandidates?: number | null;
}) {
  const [candidateContext, setCandidates] = useSafeState<CandidateContext>({
    candidates: defaultCandidates,
  });

  return (
    <SearchCandidatesContext.Provider
      value={{
        ...candidateContext,
        setCandidates,
        hasCandidates:
          !candidateContext || candidateContext.candidates === undefined
            ? undefined
            : candidateContext.candidates !== 0,
      }}
    >
      {children ? children : <Outlet />}
    </SearchCandidatesContext.Provider>
  );
}
