import { useGetTransitionalCare } from "apollo/hooks/queries";
import {
  AUCTION_STATUS_NOT_STARTED,
  QUERY_PROGRESS_SUCCEED,
  SEARCH_ACTION_GENERATE_TRANSITIONAL_CARE_FORM,
} from "core/consts";
import { getSearchActions } from "core/model/auctions";
import { Auction, Patient, SearchAction, TransitionalCare } from "core/types";
import { margin } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_24,
  FONT_WEIGHT_BOLD,
  Title,
} from "ds_legacy/materials/typography";
import { useOnEnter } from "dsl/atoms/Routes";
import PatientNavigation from "dsl/organisms/PatientNavigation";
import { useTranslations } from "translations";
import { StyledFormWrapper } from "../components";
import { TransitionalCareFormDownload } from "./DownloadSection";
import { TransitionalCareForm } from "./Form";
import { FormExplanationHeader } from "./Header";
import { FormNonSubscribed } from "./NonSubscribers";
import { FormSearchNotStarted } from "./SearchNotStarted";

export const getTransitionalCareAction = (
  auction: Auction,
): SearchAction | null => {
  const {
    [SEARCH_ACTION_GENERATE_TRANSITIONAL_CARE_FORM]: transitionalCareAction,
  } = getSearchActions(auction);

  return transitionalCareAction;
};

const ExplanationParagraph = ({
  auctionStatus,
}: {
  auctionStatus: Auction["status"];
}) => {
  const translations = useTranslations();

  if (auctionStatus === AUCTION_STATUS_NOT_STARTED) return null;

  return (
    <>
      <Body
        data-testid="started-banner"
        as="p"
        margin={margin(0, 0, 1)}
        maxWidth="100%"
      >
        {
          translations.patientForms.transitionalCareForm
            .explanationTransitionalCare
        }
      </Body>
      <Body as="p" margin={margin(0, 0, 3)} maxWidth="100%">
        {translations.patientForms.transitionalCareForm.numberOfProviders}
      </Body>
    </>
  );
};

export const TransitionalCarePresenter = ({
  auction,
  transitionalCare,
}: {
  auction: Auction;
  transitionalCare: Readonly<TransitionalCare> | undefined;
}) => {
  const translations = useTranslations();
  const translationalCareAction = getTransitionalCareAction(auction);

  if (!translationalCareAction) {
    return null;
  }

  if (translationalCareAction.context?.disabled) {
    return <FormNonSubscribed />;
  }

  const children =
    auction.status === AUCTION_STATUS_NOT_STARTED ? (
      <FormSearchNotStarted />
    ) : (
      <>
        <FormExplanationHeader transitionalCare={transitionalCare} />
        <TransitionalCareForm
          auction={auction}
          transitionalCare={transitionalCare}
        />
      </>
    );

  return (
    <StyledFormWrapper>
      <Title
        as="h2"
        margin={margin(0, 0, 3)}
        style={{ fontSize: FONT_SIZE_24, fontWeight: FONT_WEIGHT_BOLD }}
      >
        {translations.patientForms.transitionalCareForm.title}
      </Title>
      <ExplanationParagraph auctionStatus={auction.status} />
      {transitionalCare && (
        <TransitionalCareFormDownload
          careseekerName={auction.patient.careseeker?.name ?? ""}
          externalId={auction.patient.external_id ?? ""}
          userId={auction.patient.user_id ?? ""}
          transitionalCare={transitionalCare}
        />
      )}
      {children}
    </StyledFormWrapper>
  );
};

export default function PatientTransitionalCare({
  auction,
  patient,
}: {
  auction: Auction;
  patient: Patient;
}) {
  useOnEnter();
  const [queryProgress, transitionalCare] = useGetTransitionalCare({
    auctionId: auction.id,
    patientId: patient.id,
  });

  if (queryProgress !== QUERY_PROGRESS_SUCCEED) return null;

  return (
    <PatientNavigation patient={patient} auction={auction}>
      <TransitionalCarePresenter
        auction={auction}
        transitionalCare={transitionalCare}
      />
    </PatientNavigation>
  );
}
