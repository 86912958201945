import { isProd } from "core/model/utils/featureFlags";
import { Account, Careseeker } from "core/types";
import { createContext, ReactNode, useContext } from "react";

export type SenderAccountContextType = {
  account?: Account;
  activeRoles?: Array<number>;
  careseeker?: Careseeker;
  privateKey?: string;
  socialWorkers?: Array<Account>;
};

const defaultSenderAccountContext = {
  account: undefined,
  careseeker: undefined,
  activeRoles: undefined,
  socialWorkers: undefined,
  privateKey: undefined,
};

export const SenderAccountContext = createContext<SenderAccountContextType>(
  defaultSenderAccountContext,
);

export function useSenderAccountContext() {
  const context = useContext(SenderAccountContext);
  // #TODO - make sure we don't use this hook outside of SenderAccountProvider
  if (!isProd && (!context || context === defaultSenderAccountContext)) {
    console.error(
      "useSenderAccountContext must be used within SenderAccountProvider",
    );
  }
  return context;
}

export function SenderAccountProvider({
  account,
  activeRoles,
  careseeker,
  children,
  privateKey,
  socialWorkers,
}: SenderAccountContextType & { children: ReactNode }) {
  return (
    <SenderAccountContext.Provider
      value={{
        account,
        activeRoles,
        careseeker,
        privateKey,
        socialWorkers,
      }}
    >
      {children}
    </SenderAccountContext.Provider>
  );
}
