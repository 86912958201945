import Popover from "@mui/material/Popover";
import { useCreateAdditionalAuction } from "apollo/hooks/mutations";
import { useGetAvailableSearchTypes } from "apollo/hooks/queries";
import { GET_PATIENT } from "core/apollo/graphql";
import { QUERY_PROGRESS_SUCCEED } from "core/consts";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { capitaliseFirst } from "core/model/utils/strings";
import { Patient, SearchType } from "core/types";
import RSButton from "ds_legacy/components/RSButton";
import { padding, sizing } from "ds_legacy/materials/metrics";
import { MenuItem } from "dsl/atoms/MenuItem";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { useCareseekerNavigationHandlers, useDropdown } from "dsl/hooks";
import {
  PRODUCT_TOURS,
  TourAttributeWrapper,
} from "dsl/molecules/useProductTour";
import { useParallelSearchTour } from "dsl/molecules/useProductTour/tours/parallelSearchTour";
import { PlusIcon } from "lucide-react";
import { useTranslations } from "translations";

const useGetAdditionalSearchOptions = ({ patient }: { patient: Patient }) => {
  const getOntology = useGetOntology();
  const [availableSearchTypes, getAvailableSearchTypesQueryProgress] =
    useGetAvailableSearchTypes({
      patientId: patient.id,
    });

  const additionalSearchOptions = availableSearchTypes.map((value) => {
    return {
      value: value as SearchType,
      label: getOntology({
        type: "patientType",
        key: value,
      }),
    };
  });

  return [
    additionalSearchOptions,
    getAvailableSearchTypesQueryProgress,
  ] as const;
};

const useHandleAuctionCreation = ({ patient }: { patient: Patient }) => {
  const appNavigation = useCareseekerNavigationHandlers();
  const toast = useToast();
  const translations = useTranslations();
  const [createAdditionalAuction, createAdditionalAuctionQueryProgress] =
    useCreateAdditionalAuction();

  const handleAuctionCreation = (element: {
    label: string;
    value: SearchType;
  }) => {
    createAdditionalAuction({
      variables: {
        patientId: patient.id,
        input: {
          search_type: element.value,
        },
      },
      update: (cache, { data }) => {
        if (!data?.auction) {
          console.error("no auction returned");
          return;
        }

        const patientWithNewAuction = {
          ...patient,
          auctions: [...(patient.auctions || []), data.auction],
        };

        cache.writeQuery({
          query: GET_PATIENT,
          variables: { id: patient.id },
          data: {
            patient: patientWithNewAuction,
          },
        });

        appNavigation.patient.goToAssessing({
          patientId: patient.id,
          auctionId: data.auction.id,
        });
      },
      onError: (error) => {
        console.error("error creating additional auction:", error);
        toast({
          message:
            translations.careproviderApp.settings.reversePatientSearch
              .toastError,
          color: "danger",
        });
      },
    });
  };

  return [handleAuctionCreation, createAdditionalAuctionQueryProgress] as const;
};

const AddSearchDropdown = ({ patient }: { patient: Patient }) => {
  const { anchor, onClose, onOpen, open } = useDropdown();
  const translations = useTranslations();

  const [availableSearchTypes, getAvailableSearchTypesQueryProgress] =
    useGetAdditionalSearchOptions({
      patient: patient,
    });

  const [handleCreateAdditionalAuction, createAdditionalAuctionQueryProgress] =
    useHandleAuctionCreation({ patient: patient });

  useParallelSearchTour({
    hasParallelSearchOption:
      !!availableSearchTypes?.length &&
      getAvailableSearchTypesQueryProgress === QUERY_PROGRESS_SUCCEED,
  });

  if (
    !availableSearchTypes?.length &&
    getAvailableSearchTypesQueryProgress === QUERY_PROGRESS_SUCCEED
  )
    return null;

  return (
    <TourAttributeWrapper
      tourKey={PRODUCT_TOURS.parallel_search.key}
      stepKey={PRODUCT_TOURS.parallel_search.steps.new_search_tab.key}
    >
      <RSButton
        color="primary"
        id="add_search"
        LeftIcon={PlusIcon}
        loading={createAdditionalAuctionQueryProgress}
        onClick={onOpen}
        variant="text"
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {translations.actions.addSearch}
      </RSButton>

      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchor}
        open={open}
        onClose={onClose}
      >
        {availableSearchTypes.map(
          (element: { label: string; value: SearchType }) => (
            <MenuItem
              sx={{
                width: sizing(16),
                display: "flex",
                justifyContent: "center",
                padding: padding(1),
              }}
              onClick={() => handleCreateAdditionalAuction(element)}
              key={element.value}
            >
              {capitaliseFirst(element.label.toLowerCase())}
            </MenuItem>
          ),
        )}
      </Popover>
    </TourAttributeWrapper>
  );
};

export default AddSearchDropdown;
