import {
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "core/consts";
import { Auction, QueryProgress, RehabFormName } from "core/types";
import { format, fromUnixTime } from "date-fns";
import { useWarnBeforeExit } from "dsl/hooks/useWarnBeforeExit";
import { useWarnBeforeNavigate } from "dsl/hooks/useWarnBeforeNavigate";
import { ReactNode, useState } from "react";
import { RehabFormFormGetters } from "./RehabForms/configs";
import { TransitionalCareFormGetters } from "./TransitionalCare/Form/Import/utils";

export function sanitize(value: unknown) {
  if (typeof value === "string") {
    return value.replace(/[\u202C\u200E\u200F\u202A-\u202E]/g, "").trim();
  }
  if (typeof value === "number") {
    const cleaned = String(value)
      .replace(/[\u202C\u200E\u200F\u202A-\u202E]/g, "")
      .trim();
    return isNaN(Number(cleaned)) ? null : Number(cleaned);
  }
  return value;
}

export const useImportFormData = ({
  auction,
  formGetters,
  formOnChange,
}: {
  auction: Auction;
  formGetters:
    | TransitionalCareFormGetters
    | RehabFormFormGetters<RehabFormName>;
  formOnChange: (value: any, statePath: string, validation: any) => void;
}) => {
  const [queryProgress, setQueryProgress] = useState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const importFormData = () => {
    setQueryProgress(QUERY_PROGRESS_PENDING);
    Object.entries(formGetters).forEach(([field, fieldGetter]) => {
      if (!fieldGetter) return;
      const value = fieldGetter(auction);
      const sanitizedValue = sanitize(value);

      formOnChange(sanitizedValue ?? null, field, null);
    });
    setQueryProgress(QUERY_PROGRESS_SUCCEED);
    return;
  };
  return { importProgress: queryProgress, importFormData };
};

export const ExitPreventionWrapper = ({
  children,
  dirty,
}: {
  children: ReactNode;
  dirty: boolean;
}) => {
  useWarnBeforeExit({ skip: !dirty });
  useWarnBeforeNavigate({
    skip: !dirty,
  });

  return <>{children}</>;
};

export function assertUnreachable(x: never): never {
  throw new Error(`Unhandled case: ${x}`);
}

export const formatDate = (timestamp: number, locale: Locale) =>
  format(fromUnixTime(timestamp), "P", { locale });
