import {
  AT_HOME_SITUATION,
  GENDER_DIVERSE,
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_UNSPECIFIED,
  HOME_CARE_SITUATION,
  MUSCOSKELETAL_RESILIENCE,
  REHAB_ADMISSION,
  REHAB_FOCUS,
  TRANSPORTATION_CODE,
  TRANSPORTATION_SUBTYPE,
  TRANSPORTATION_TYPE,
  TYPE_OF_REHAB,
} from "core/consts";
import { isTest } from "core/model/utils/featureFlags";
import {
  AtHomeSituation,
  GendersExtended,
  HomeCareSituation,
  SingerAssessment,
  TypeOfRehab,
  ValueOf,
} from "core/types";
import { format, utcToZonedTime } from "date-fns-tz";
import { GKVGeneralFormData } from "dsl/ecosystems/PatientForms/RehabForms/GKVGeneralForm/modelDefinition";
import { GKVMedicalFormData } from "dsl/ecosystems/PatientForms/RehabForms/GKVMedicalForm/modelDefinition";
import { v4 as uuidv4 } from "uuid";

function mapGender(
  value: GendersExtended | null | undefined,
): string | undefined {
  switch (value) {
    case GENDER_MALE:
      return "m";
    case GENDER_FEMALE:
      return "w";
    case GENDER_DIVERSE:
      return "d";
    case GENDER_UNSPECIFIED:
      return "x";

    default:
      return undefined;
  }
}

export const OPERATION_INDEXES = {
  OP1: 1,
  OP2: 2,
  OP3: 3,
  OP4: 4,
  OP5: 5,
} as const;
export const NUMBER_OF_OPS_FIELDS = 10 as const;

const REHAB_TYPE_VALUES = {
  STATIONARY: 1, // stationär
  AMBULANT: 2, // ambulant ganztägig
  AMBULANT_MOBIL: 4, // ambulant mobile
} as const;

function mapRecommendedRehabType(
  value: TypeOfRehab | undefined | null,
): 1 | 2 | 4 | undefined {
  switch (value) {
    case TYPE_OF_REHAB.AMBULANT:
      return REHAB_TYPE_VALUES.AMBULANT; // ambulant ganztägig
    case TYPE_OF_REHAB.AMBULANT_MOBIL:
      return REHAB_TYPE_VALUES.AMBULANT_MOBIL; // ambulant mobile
    case TYPE_OF_REHAB.STATIONARY:
      return REHAB_TYPE_VALUES.STATIONARY; // stationär
    default:
      return undefined;
  }
}

const specialRequirementsMap: Record<string, string> = {
  special_requirements_chemotherapy: "01",
  special_requirements_immunosuppressants: "02",
  special_requirements_catheter: "03",
  special_requirements_peg: "04",
  special_requirements_isolation: "05",
  special_requirements_ventilation: "06",
  special_requirements_tracheostoma: "07",
  special_requirements_weaning: "08",
  special_requirements_prosthesis: "09",
  special_requirements_overlength_bed: "10",
  special_requirements_obesity: "11",
  special_requirements_impairment: "12",
  special_requirements_relatives: "13",
  special_requirements_other: "14",
  special_requirements_dialysis: "15",
  special_requirements_decubitus: "16",
  special_requirements_weight_bed: "17",
};

const beeintraechtigungItems: [
  keyof NonNullable<SingerAssessment["form"]>,
  string,
][] = [
  ["food", "01"],
  ["dressing_and_undressing", "02"],
  ["shower", "03"],
  ["hygiene", "04"],
  ["toilet_use", "05"],
  ["urination", "06"],
  ["stool", "07"],
  ["transfer", "08"],
  ["walking", "09"],
  ["stairs", "10"],
  ["wheelchair", "11"],
  ["orientation", "12"],
  ["talking", "13"],
  ["listening", "14"],
  ["reading", "15"],
  ["writing", "16"],
  ["concentration", "17"],
  ["planning", "18"],
  ["social_interaction", "19"],
  ["domestic_life_household", "20"],
];

const mapTransportationCode = (
  type: ValueOf<typeof TRANSPORTATION_TYPE> | undefined | null,
  taxiDetail: ValueOf<typeof TRANSPORTATION_SUBTYPE> | undefined | null,
  ambulanceDetail: ValueOf<typeof TRANSPORTATION_SUBTYPE> | undefined | null,
): ValueOf<typeof TRANSPORTATION_CODE> | undefined => {
  switch (type) {
    case TRANSPORTATION_TYPE.PUBLIC:
      return TRANSPORTATION_CODE.PUBLIC;
    case TRANSPORTATION_TYPE.CAR:
      return TRANSPORTATION_CODE.CAR;
    case TRANSPORTATION_TYPE.TAXI:
      switch (taxiDetail) {
        case TRANSPORTATION_SUBTYPE.WHEELCHAIR:
          return TRANSPORTATION_CODE.TAXI_WHEELCHAIR;
        case TRANSPORTATION_SUBTYPE.STRETCHER:
          return TRANSPORTATION_CODE.TAXI_STRETCHER;
        case TRANSPORTATION_SUBTYPE.LYING_DOWN:
          return TRANSPORTATION_CODE.TAXI_LYING_DOWN;
        default:
          return undefined;
      }
    case TRANSPORTATION_TYPE.AMBULANCE:
      switch (ambulanceDetail) {
        case TRANSPORTATION_SUBTYPE.WHEELCHAIR:
          return TRANSPORTATION_CODE.AMBULANCE_WHEELCHAIR;
        case TRANSPORTATION_SUBTYPE.STRETCHER:
          return TRANSPORTATION_CODE.AMBULANCE_STRETCHER;
        case TRANSPORTATION_SUBTYPE.LYING_DOWN:
          return TRANSPORTATION_CODE.AMBULANCE_LYING_DOWN;
        default:
          return undefined;
      }
    default:
      return undefined;
  }
};

const timeZone = "Europe/Berlin";

function formatDateYYYYMMDD(
  date: Date | number | null | undefined,
): string | undefined {
  if (!date) {
    return undefined;
  }

  let dateObject: Date;

  if (typeof date === "number") {
    dateObject = new Date(date * 1000);
  } else {
    dateObject = date;
  }

  const zonedDate = utcToZonedTime(dateObject, timeZone);

  return format(zonedDate, "yyyyMMdd");
}

function toFlag(value: any): string {
  if (typeof value === "boolean") return value ? "1" : "0";
  if (value === "true" || value === 1 || value === "1") return "1";
  return "0";
}

function mapLivingSituation(
  living_situation?: HomeCareSituation | null,
  living_situation_at_home?: AtHomeSituation | null,
): "1" | "2" | "3" | "4" | undefined {
  if (living_situation === HOME_CARE_SITUATION.HOME) {
    if (living_situation_at_home === AT_HOME_SITUATION.ALONE) return "1";
    if (living_situation_at_home === AT_HOME_SITUATION.RELATIVES) return "2";
    return undefined;
  }

  if (living_situation === HOME_CARE_SITUATION.ASSISTED_LIVING) return "3";
  if (living_situation === HOME_CARE_SITUATION.CARE_HOME) return "4";

  return undefined;
}

export function mapFormToRehabRequest(
  form: GKVGeneralFormData & GKVMedicalFormData,
) {
  if (!isTest) {
    console.log(form);
  }
  const now = new Date();
  const zonedDate = utcToZonedTime(now, timeZone);
  const dateiID = uuidv4();
  const erstellungsdatum = formatDateYYYYMMDD(now);
  const erstellungszeit = format(zonedDate, "HHmmss");

  const rehabType = mapRecommendedRehabType(form.recommended_reha_type);
  const verkehrsmittel = mapTransportationCode(
    form.transport_type,
    form.transport_type_taxi_specification,
    form.transport_type_ambulance_specification,
  );

  const operations = Object.values(OPERATION_INDEXES)
    .map((index) => {
      const date = form[`operation_${index}_date`];
      const ops = form[`operation_${index}_ops`];
      const woundClosed = form[`operation_${index}_wound_closed`];

      const hasData = date || ops?.length || woundClosed !== null;

      if (!hasData) return null;

      return {
        "arh:Wundheilung": woundClosed === 1 ? "1" : "0",
        "arh:OPS_Codes": {
          "arh:OPS": ops ?? [],
        },
        "arh:OP_Datum": formatDateYYYYMMDD(date),
      };
    })
    .truthy();

  return {
    Nutzdaten: {
      "@xmlns": "http://www.gkv-datenaustausch.de/XMLSchema/GKH_DAT/1.1",
      "@xmlns:gdb": "http://www.gkv-datenaustausch.de/XMLSchema/GKH_basis/1.1",
      "@xmlns:aar":
        "http://www.gkv-datenaustausch.de/XMLSchema/GKH_AAR_DAT/1.1",
      "@xmlns:arh":
        "http://www.gkv-datenaustausch.de/XMLSchema/GKH_AAR_ARH/1.1",
      "@xmlns:feh": "http://www.gkv-datenaustausch.de/XMLSchema/GKH_FEH/1.1",
      "@xmlns:sto": "http://www.gkv-datenaustausch.de/XMLSchema/GKH_STO/1.1",
      Header: {
        "gdb:Verfahrenskennung": "TGKH0",
        "gdb:Unterverfahren": "AAR",
        "gdb:Nachrichtentyp": "ARH",
        "gdb:Logische_Version": "1.1.0",
        "gdb:Erstellungsdatum": erstellungsdatum,
        "gdb:Erstellungszeit": erstellungszeit,
        "gdb:Datei_ID": dateiID,
        "gdb:Absender": {
          "gdb:Krankenhaus": {
            "gdb:Institutionskennzeichen": "", // #TODO where to fetch from?
          },
        },
        "gdb:Empfaenger": {
          "gdb:Krankenkasse": {
            "gdb:Institutionskennzeichen": "", // #TODO where to fetch from?
          },
        },
      },
      Body: {
        Anschlussrehabilitation: {
          "aar:Antrag": {
            "aar:Versicherter": {
              "gdb:Nachname": form.patient_last_name,
              "gdb:Vorname": form.patient_first_name,
              "gdb:Geburtsdatum": form.patient_birthdate
                ? formatDateYYYYMMDD(form.patient_birthdate)
                : undefined,
              "gdb:Krankenversichertennummer": form.insurance_number,
              "gdb:Geschlecht": mapGender(form.gender),
              "gdb:Strasse": form.patient_street,
              "gdb:Hausnummer": form.patient_housenumber,
              "gdb:Postleitzahl": form.patient_zipcode,
              "gdb:Ort": form.patient_city,
              "gdb:Anschriftenzusatz": undefined,
              "gdb:Laenderkennzeichen": "D", // #TODO should this always be "D" (for Deutschland)?
              "gdb:Telefon": form.patient_phone_number,
              "gdb:Staatsangehoerigkeit": undefined, //  #TODO is this required?
              "gdb:KH_internes_Kennzeichen": form.case_id,
            },
            "aar:Antrag": {
              "arh:Verarbeitungskennzeichen": 10,
              "arh:Angaben_des_Versicherten": {
                "arh:Antrag_Pflegebeduerftigkeit": toFlag(
                  form.insured_details_application_insurance_benefits,
                ),
                "arh:Rentenantragstellung": {
                  "arh:Altersrente": toFlag(form.retirement_pension_applied),
                  "arh:Traeger_der_Altersrente":
                    form.retirement_pension_company,
                  "arh:Erwerbsminderungsrente": toFlag(
                    form.retirement_pension_earning_capacity_applied,
                  ),
                  "arh:Traeger_der_Erwerbsminderungsrente":
                    form.retirement_earning_capacity_company,
                },
                "arh:Haeusliche_Situation": {
                  "arh:Stockwerk": form.living_lift_description || "u",
                  "arh:Aufzug": toFlag(form.living_lift),
                  "arh:Besonderheiten": form.living_special,
                },
                "arh:Haeusliche_Versorgungssituation": {
                  "arh:Wohnsituation": mapLivingSituation(
                    form.living_situation,
                    form.living_situation_at_home,
                  ),
                  "arh:Versorgung_Sichergestellt": toFlag(
                    form.domestic_situation_is_safe,
                  ),
                },
                ...((form.general_practitioner_first_name ||
                  form.general_practitioner_last_name) && {
                  "arh:Hausarzt": {
                    "arh:Nachname": form.general_practitioner_last_name,
                    "arh:Vorname": form.general_practitioner_first_name,
                    "arh:Strasse": form.general_practitioner_street,
                    "arh:Hausnummer": form.general_practitioner_housenumber,
                    "arh:Postleitzahl": form.general_practitioner_zipcode,
                    "arh:Ort": form.general_practitioner_city,
                    "arh:Telefon": form.general_practitioner_phone_number,
                  },
                }),
                ...(form.insured_details_guardian_1_type && {
                  "arh:Betreuung_Vollmacht_Vertreter": [
                    {
                      "arh:Kennzeichen_Vertretung": `${form.insured_details_guardian_1_type}`,
                      "arh:Nachname": form.insured_details_guardian_1_last_name,
                      "arh:Vorname": form.insured_details_guardian_1_first_name,
                      "arh:Strasse": form.insured_details_guardian_1_street,
                      "arh:Hausnummer":
                        form.insured_details_guardian_1_house_number,
                      "arh:Postleitzahl":
                        form.insured_details_guardian_1_zipcode,
                      "arh:Ort": form.insured_details_guardian_1_city,
                      "arh:Telefon": form.insured_details_guardian_1_phone,
                    },
                    ...(form.insured_details_guardian_2_type
                      ? [
                          {
                            "arh:Kennzeichen_Vertretung": `${form.insured_details_guardian_2_type}`,
                            "arh:Nachname":
                              form.insured_details_guardian_2_last_name,
                            "arh:Vorname":
                              form.insured_details_guardian_2_first_name,
                            "arh:Strasse":
                              form.insured_details_guardian_2_street,
                            "arh:Hausnummer":
                              form.insured_details_guardian_2_house_number ||
                              "",
                            "arh:Postleitzahl":
                              form.insured_details_guardian_2_zipcode,
                            "arh:Ort": form.insured_details_guardian_2_city,
                            "arh:Telefon":
                              form.insured_details_guardian_2_phone,
                          },
                        ]
                      : []),
                  ],
                }),

                "arh:Kommunikation": {
                  "arh:Deutsche_Sprache": toFlag(
                    form.communication_in_german_possible,
                  ),
                  "arh:Sprache": form.communication_other_language || undefined,
                },
                "arh:Anforderungen_Wuensche": form.reason_for_reha_clinic,
              },
              "arh:Willenserklaerung_Unterschrift": "1", // #TODO what should the value be?
              "arh:Entbindung_Schweigepflicht": "1", // #TODO what should the value be?
              "arh:Einwilligung_Uebermittlung": "1", // #TODO what should the value be?
              "arh:Angaben_des_Krankenhauses": {
                "arh:Standortnummer": undefined, // #TODO where do I get this from? https://reimbursement.institute/glossar/standortnummer/#
                "arh:Ansprechpartner": {
                  "arh:Vorname": form.social_worker_first_name,
                  "arh:Nachname": form.social_worker_last_name,
                  "arh:Telefon": form.social_worker_phone_number,
                  "arh:Fax": form.social_worker_fax_number,
                },
                "arh:Rehaeinrichtung": {
                  ...(form.prefered_clinic_contacted === 2
                    ? {
                        "arh:Rehaeinrichtung_nicht_angefragt": 1,
                      }
                    : {
                        "arh:Angefragte_Rehaeinrichtung": {
                          "arh:Anschrift_Rehaeinrichtung": (() => {
                            const raw =
                              form.prefered_clinic_contacted_details || "";
                            const lines = raw
                              .split("\n")
                              .map((line) => line.trim())
                              .filter(Boolean);

                            const [
                              name,
                              streetLine,
                              postcodeCityLine,
                              phoneLine,
                            ] = lines;
                            const streetMatch =
                              streetLine?.match(/(.*)\s+(\S+)$/);
                            const postcodeCityMatch =
                              postcodeCityLine?.match(/^(\d{4,10})\s+(.*)$/);

                            // #TODO can't extract these field correctly as it's all in one field in the digital form
                            return {
                              "arh:Name": name ?? "Name",
                              "arh:Strasse":
                                (streetMatch?.[1] || streetLine) ?? "Strasse",
                              "arh:Hausnummer": streetMatch?.[2] ?? "99",
                              "arh:Postleitzahl":
                                postcodeCityMatch?.[1] ?? "10405",
                              "arh:Ort": postcodeCityMatch?.[2] ?? "Berlin",
                              "arh:Telefon": phoneLine ?? "15739290303",
                            };
                          })(),
                        },
                      }),
                  "arh:Fruehestmoegliche_Aufnahme": formatDateYYYYMMDD(
                    form.prefered_clinic_contacted_start_date,
                  ),
                },

                "arh:Krankenhausbehandlung": {
                  "arh:Beginn": formatDateYYYYMMDD(form.admission_date),
                  "arh:Ende": formatDateYYYYMMDD(form.discharge_date),
                  "arh:Version_ICD_OPS": "2023",
                },
                "arh:Kommunikation": {
                  "arh:Deutsche_Sprache": toFlag(
                    form.communication_in_german_possible,
                  ),
                  "arh:Sprache": form.communication_other_language ?? undefined,
                },
              },

              "arh:Fruehrehabilitation": toFlag(
                form.early_rehabilitation_measures,
              ),
              "arh:Rehabilitationsziel": form.rehabilitation_goals,
              "arh:Verlegung": {
                "arh:Kennzeichen_Verlegung": form.rehab_admission_type,
                "arh:Datum_Verlegung":
                  form.rehab_admission_type ===
                  REHAB_ADMISSION.OUTSIDE_TWO_WEEKS
                    ? formatDateYYYYMMDD(
                        form.rehab_admission_outside_two_weeks_start_date,
                      )
                    : form.rehab_admission_type ===
                      REHAB_ADMISSION.WITHIN_TWO_WEEKS
                    ? formatDateYYYYMMDD(
                        form.rehab_admission_within_two_weeks_start_date,
                      )
                    : undefined,
                "arh:Begruendung":
                  form.rehab_admission_outside_two_weeks_explanation ??
                  undefined,
              },
              "arh:Funktionsdiagnosen": {
                "arh:Funktionsdiagnose": [0, 1, 2, 3, 4]
                  .map((i) => {
                    const code =
                      form[`icd_code_${i + 1}_code` as "icd_code_1_code"];
                    const diagnosis =
                      form[
                        `icd_code_${i + 1}_diagnosis` as "icd_code_1_diagnosis"
                      ];
                    const reason =
                      form[`icd_code_${i + 1}_reason` as "icd_code_1_reason"];

                    return code || diagnosis || reason
                      ? {
                          "arh:Lfd_Nr": String(i + 1),
                          "arh:Diagnose": diagnosis,
                          "arh:ICD_10_GM": code,
                          "arh:Ursache": reason,
                        }
                      : undefined;
                  })
                  .filter(Boolean),
              },
              "arh:Behandlungen": {
                "arh:Operation_durchgefuehrt": form.surgery_performed,
                ...(!!operations.length && {
                  "arh:Operationen": {
                    "arh:Operation": operations,
                  },
                }),
                ...(form.other_treatments && {
                  "arh:Andere_Behandlung": form.other_treatments,
                }),
              },
              "arh:Komplikationen":
                form.complication_in_treatment_process !== undefined
                  ? {
                      "arh:Komplikationen_aufgetreten":
                        form.complication_in_treatment_process === 1
                          ? "1"
                          : "0",
                      ...(form.complication_in_treatment_process === 1 && {
                        "arh:Art_der_Komplikation": {
                          ...(form.complication_cause_maindiagnosis_description && {
                            "arh:Komplikation_mit_AR_Diagnose":
                              form.complication_cause_maindiagnosis_description,
                          }),
                          ...(form.complication_cause_cardiovascular_description && {
                            "arh:Kardiovaskulaere_Komplikation":
                              form.complication_cause_cardiovascular_description,
                          }),
                          ...(form.complication_cause_other_description && {
                            "arh:Sonstige_Komplikation":
                              form.complication_cause_other_description,
                          }),
                        },
                      }),
                      "arh:Keime": {
                        "arh:Keimbesiedelung": String(
                          form.infections_state ?? "0",
                        ),
                        ...(form.infections && {
                          "arh:Art_der_Keime": form.infections,
                        }),
                      },
                    }
                  : undefined,
              "arh:Alltagsrelevante_Beeintraechtigungen": (() => {
                const beeintraechtigungen = beeintraechtigungItems
                  .map(([field, code]) => {
                    const val =
                      form.singer_assessment_form?.[
                        field as keyof typeof form.singer_assessment_form
                      ];
                    return typeof val === "number" && val >= 0 && val <= 5
                      ? { "arh:Item": code, "arh:Punkte": val }
                      : null;
                  })
                  .filter(Boolean) as {
                  "arh:Item": string;
                  "arh:Punkte": number;
                }[];

                return beeintraechtigungen.length > 0
                  ? {
                      "arh:Beeintraechtigung": beeintraechtigungen,
                      "arh:Gesamtpunkte":
                        form.singer_assessment_score ??
                        beeintraechtigungen.reduce(
                          (sum, i) => sum + i["arh:Punkte"],
                          0,
                        ),
                    }
                  : undefined;
              })(),
              "arh:Rehabilitationsfaehigkeit": {
                "arh:Ausreichende_Therapiebelastbarkeit": toFlag(
                  form.reha_capacity,
                ),
                "arh:Belastbarkeit_nach_OP":
                  form.reha_capacity === MUSCOSKELETAL_RESILIENCE.FULLY
                    ? "1"
                    : form.reha_capacity === MUSCOSKELETAL_RESILIENCE.PARTIALLY
                    ? "2"
                    : form.reha_capacity === MUSCOSKELETAL_RESILIENCE.STABLE
                    ? "3"
                    : undefined,
                "arh:Teilbelastbar_mit":
                  form.reha_capacity === MUSCOSKELETAL_RESILIENCE.PARTIALLY &&
                  form.reha_capacity_partial
                    ? parseInt(form.reha_capacity_partial)
                    : undefined,
                "arh:Anpassung_Interimsprothese_erfolgt": toFlag(
                  form.interim_prosthesis_fitted,
                ),
                "arh:Besonderheiten": form.reha_capacity_other || undefined,
                "arh:Kriterien_FR_Index":
                  Array.isArray(form.reha_capacity_early_rehab_criteria) &&
                  form.reha_capacity_early_rehab_criteria.length > 0
                    ? {
                        "arh:Kriterium":
                          form.reha_capacity_early_rehab_criteria,
                      }
                    : undefined,
              },
              "arh:Zusammenfassende_Bewertung": {
                "arh:Schwerpunkt": {
                  "arh:Rehaschwerpunkt": String(form.rehab_focus).padStart(
                    2,
                    "0",
                  ),
                  "arh:Erlaeuterung":
                    form.rehab_focus == REHAB_FOCUS.OTHER
                      ? form.rehab_focus_other_description
                      : undefined,
                },
                "arh:Empfohlene_Rehabilitationsart": rehabType,
                "arh:Begruendung_Rehabilitationsart":
                  rehabType === REHAB_TYPE_VALUES.STATIONARY
                    ? form.recommended_reha_type_stationary || undefined
                    : rehabType === REHAB_TYPE_VALUES.AMBULANT_MOBIL
                    ? form.recommended_reha_type_ambulant_mobil || undefined
                    : undefined,
                "arh:Fall_Paragraph_16": form.rehab_directive_state,
                "arh:Begruendungen": {
                  "arh:Begruendung": form.rehab_directive_type,
                },
              },
              "arh:Weitere_Informationen": {
                "arh:Besondere_Anforderungen":
                  form.special_requirements_required === 1
                    ? {
                        "arh:Anforderungen": {
                          "arh:Anforderung": Object.entries(
                            specialRequirementsMap,
                          )
                            .filter(([key]) => form[key as keyof typeof form])
                            .map(([_, code]) => code),
                          ...(form.special_requirements_dialysis_description && {
                            "arh:Dialyseverfahren":
                              form.special_requirements_dialysis_description ??
                              undefined,
                          }),
                          ...(form.special_requirements_other_description && {
                            "arh:Erlaeuterung":
                              form.special_requirements_other_description ??
                              undefined,
                          }),
                          ...(form.special_requirements_relatives && {
                            "arh:Pflegegrad_Angehoeriger":
                              form.special_requirements_relatives_description ??
                              undefined,
                          }),
                          ...(form.special_requirements_decubitus && {
                            "arh:Grad_Dekubitus":
                              form.special_requirements_decubitus_description ??
                              undefined,
                          }),
                          ...(form.special_requirements_weight_bed && {
                            "arh:Last_bis":
                              form.special_requirements_weight_bed_description ??
                              undefined,
                          }),
                        },
                      }
                    : {
                        "arh:Keine_besonderen_Anforderungen": "1",
                      },

                "arh:Anreise": verkehrsmittel
                  ? {
                      "arh:Verkehrsmittel": verkehrsmittel,
                      "arh:Begruendung_KTW":
                        form.transport_type_ambulance_description || undefined,
                      "arh:Begleitperson": toFlag(
                        form.require_accompanying_person,
                      ),
                    }
                  : undefined,
                "arh:Krankenhausarzt": {
                  "arh:Nachname": form.doctor_in_charge_in_hospital_last_name,
                  "arh:Vorname": form.doctor_in_charge_in_hospital_first_name,
                  "arh:Titel": undefined,
                  "arh:Telefon": form.doctor_in_charge_in_hospital_phone,
                  "arh:Datum": undefined, // #TODO Date of the diagnosis by the hospital doctor?
                },
              },
            },
          },
        },
      },
    },
  };
}
