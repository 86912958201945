import { useTheme } from "@mui/styles";
import { useUpdateSearchCandidates } from "apollo/hooks/mutations";
import {
  useGetPatientQuery,
  useGetStaticSearchCandidates,
} from "apollo/hooks/queries";
import {
  CANDIDATES_STATUS_SUCCESS,
  QUERY_PROGRESS_NOT_STARTED,
  SEARCH_ACTION_UPDATE_SOCIAL_WORKER,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOME_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_REHABILITATION,
  SEARCH_TYPE_TRANSPORT,
} from "core/consts";
import {
  GetNameOptions,
  convertSocialWorkers,
  getAccountValue,
} from "core/model/accounts";
import { findSearchAction } from "core/model/auctions";
import { getPatientName } from "core/model/patients";
import { isDesignBatch1 } from "core/model/utils/featureFlags";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import {
  Account,
  Auction,
  AuctionNoPatient,
  Patient as PatientType,
  SearchType,
} from "core/types";
import { DotWithBorder } from "ds_legacy/components/Dot";
import RSButton from "ds_legacy/components/RSButton";
import { SelectOption } from "ds_legacy/components/Select/types";
import Spinner from "ds_legacy/components/Spinner";
import {
  ICON_DARK,
  SUB_HEADER_BAR_BACKGROUND,
  WHITE,
} from "ds_legacy/materials/colors";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import {
  APP_BAR_HEIGHT,
  PATIENT_MENU_HEIGHT,
  Z_INDEX_PATIENT_MENU,
  border,
  dp,
  margin,
  padding,
  sizing,
} from "ds_legacy/materials/metrics";
import { Body, FONT_WEIGHT_BOLD, Title } from "ds_legacy/materials/typography";
import { usePatientEncryption, usePrint } from "dsl/atoms/Contexts";
import { useNavbarHeightContext } from "dsl/atoms/Navbar/Context/NavbarHeightContext";
import {
  shouldDisplayCandidates,
  useCandidates,
} from "dsl/atoms/SearchCandidates";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { useCareseekerNavigationHandlers } from "dsl/hooks/useNavigationHandlers";
import useSearchAction from "dsl/hooks/useSearchAction";
import SocialWorkerChange, {
  differentAssignee,
} from "dsl/molecules/SocialWorkerChange";
import { PRODUCT_TOURS, tourAttributes } from "dsl/molecules/useProductTour";
import { ArchivePatient } from "dsl/organisms/DeletePatientButton";
import { debounce } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { UserIcon } from "lucide-react";
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Outlet, useMatch } from "react-router-dom";
import styled, { CSSProperties } from "styled-components";
import { useTranslations } from "translations";
import Translations from "translations/types";
import AddSearchDropDown from "./AddSearch/AddSearchDropdown";
import SocialWorkerAutoComplete from "./SocialWorkerAutocomplete";
import {
  FieldsHolderType,
  extractPatientFields,
  getAuctionFromFields,
} from "./transform";
import { useSenderAccountContext } from "context/SenderAccountContext";

export type WatchedFieldContextType = {
  setWatchedFields: Dispatch<SetStateAction<FieldsHolderType>>;
  watchedFields: FieldsHolderType | null;
};

const PatientMenuChildrenWrapper = styled.div<{ print?: boolean }>`
  display: ${(props) => (props.print ? "block" : "flex")};
  flex-direction: column;
`;

const EmptyBlock = styled.div<{ navbarHeight: number }>`
  z-index: 0;
  height: ${({ navbarHeight }) =>
    isDesignBatch1()
      ? dp(PATIENT_MENU_HEIGHT + navbarHeight)
      : dp(PATIENT_MENU_HEIGHT)};
  width: 100%;
`;

const PatientMenuContainer = styled.div<{
  navbarHeight: number;
  storybookPosition?: CSSProperties["position"];
}>`
  background-color: ${WHITE};
  box-sizing: border-box;
  height: ${dp(PATIENT_MENU_HEIGHT)};
  position: ${({ storybookPosition }) => storybookPosition || "fixed"};
  top: ${({ navbarHeight }) =>
    isDesignBatch1() ? dp(navbarHeight) : dp(APP_BAR_HEIGHT)};
  width: 100vw;
  z-index: ${Z_INDEX_PATIENT_MENU};
`;

const EditIconWrapper = styled.span`
  display: none;
  margin-right: ${dp(8)};
`;

const InfoStripe = styled.div`
  display: flex;
  width: 100%;
  height: ${sizing(8.5)};
  max-height: ${sizing(8.5)};
  justify-content: space-between;
  align-items: center;
  overflow-y: hidden;
  ${({ onClick }) =>
    onClick &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${SUB_HEADER_BAR_BACKGROUND};
    }
  `};
  &:hover ${EditIconWrapper} {
    ${({ onClick }) => onClick && `display: inline-block;`};
  }
`;

const InfoWrapper = styled.span`
  display: flex;
  margin-bottom: ${dp(4)};
  align-items: center;
`;

const TabsContainer = styled.div<{ justify?: string }>`
  display: flex;
  justify-content: ${({ justify }) => justify ?? `space-between`};
  align-items: center;
  padding: ${padding(0, 3, 0, 1)};
  overflow-y: hidden;
  box-sizing: border-box;
  height: ${sizing(5.5)};
  border-bottom: ${border({ color: "rgba(0, 0, 0, 0.12)" })};
`;

export const DotContainer = styled.span`
  display: flex;
  justify-content: center;
  margin: ${margin(0, 1)};
`;

function isValidPatient(
  patient: PatientType | undefined,
  auction: Auction | undefined,
): boolean {
  return (
    auction?.solutions != null &&
    auction.solutions.length > 0 &&
    auction?.profile?.search_location?.latitude != null &&
    auction?.profile?.search_location?.longitude != null &&
    patient?.profile != null &&
    auction?.start_date != null &&
    (patient?.profile.age != null || patient?.profile.age_interval != null)
  );
}

export function PatientIdentifier({ patient }: { patient: PatientType }) {
  const { encryptionAvailable } = usePatientEncryption();
  const patientName = getPatientName(patient);

  if (encryptionAvailable && patientName)
    return (
      <Title whiteSpace="nowrap">
        <span
          data-testid="patient_name"
          style={{ fontWeight: FONT_WEIGHT_BOLD }}
        >
          {patientName}
        </span>
        &nbsp;<span data-testid="patient_id">({patient.user_id})</span>
      </Title>
    );

  return (
    <Title whiteSpace="nowrap">
      Patient&nbsp;
      <span data-testid="patient_id">{patient.user_id || ""}</span>
    </Title>
  );
}

const PatientAssigneeHorizontalLayout = ({
  children,
}: {
  children: ReactNode;
}) => (
  <HorizontalLayout
    data-testid="patient-assignee"
    aligned
    style={{
      maxHeight: "100%",
      minWidth: sizing(35),
      margin: margin(0, 2, 0.375, 2),
    }}
  >
    {children}
  </HorizontalLayout>
);

export function PatientAssignee({
  auction,
  patient,
}: {
  auction: Auction;
  patient: PatientType;
}) {
  const isAssessment = useMatch(
    "app/clinic/dashboard/patient/:patient_id/auction/:auction_id/assessing/*",
  );

  const toast = useToast();
  const [open, setOpen] = useState(false);
  const nameOptions: GetNameOptions = {
    withSalutation: false,
    withAcademicTitle: false,
  };
  const translations = useTranslations();
  const getOntology = useGetOntology();
  const { account, socialWorkers } = useSenderAccountContext();
  const assignee = auction.patient.social_worker;

  const currentValue = useMemo(() => {
    return getAccountValue(assignee, getOntology, nameOptions);
  }, [assignee, nameOptions]);

  const assigneeRef = useRef<Account | undefined>(assignee);

  const options = useMemo(
    () =>
      convertSocialWorkers(socialWorkers, getOntology, nameOptions).reduce<
        SelectOption[]
      >((acc, { name, value }) => {
        if (name && value) {
          return [...acc, { label: name, value, id: value }];
        }
        return acc;
      }, []),
    [socialWorkers, translations, nameOptions],
  );

  const canUpdateSocialWorker = findSearchAction(
    auction,
    SEARCH_ACTION_UPDATE_SOCIAL_WORKER,
  );

  const [updateSocialWorker, updateSocialWorkerProgress, resetProgress] =
    useSearchAction({
      actionType: SEARCH_ACTION_UPDATE_SOCIAL_WORKER,
    });

  const onChange = useCallback(
    (newValue: SelectOption) => {
      const social_worker = socialWorkers?.find(({ id }) => id === newValue.id);
      if (social_worker != null) {
        updateSocialWorker({
          auction,
          context: { social_worker_id: social_worker.id },
          onCompleted: (auction) => {
            const oldAssignee = assigneeRef?.current;
            const [shouldNotify] = differentAssignee(
              auction?.patient || patient,
              { ...patient, social_worker: oldAssignee },
              account,
            );

            if (shouldNotify) setOpen(true);
            else resetProgress();

            if (assigneeRef?.current != null) assigneeRef.current = assignee;
          },
          onError: () =>
            toast({
              message: translations.auctionRequest.uploadError,
              color: "danger",
            }),
        });
      }
    },
    [updateSocialWorker, socialWorkers],
  );

  if (socialWorkers === null)
    return (
      <PatientAssigneeHorizontalLayout>
        <Spinner size="small" id="assignee-options" />
      </PatientAssigneeHorizontalLayout>
    );

  if (isAssessment) return null;

  return (
    <>
      <PatientAssigneeHorizontalLayout>
        <UserIcon
          style={{ color: ICON_DARK, margin: margin(0, 0.5, 0, 0) }}
          size={16}
        />
        <Body margin={margin(0, 0, 1 / 8, 0)}>
          {translations.patient.responsiblePerson}
          {translations.general.colon}
          &nbsp;
        </Body>
        <SocialWorkerAutoComplete
          currentValue={currentValue}
          defaultValue={
            options.find(({ id }) => id === currentValue) as SelectOption
          }
          disabled={
            updateSocialWorkerProgress !== QUERY_PROGRESS_NOT_STARTED ||
            socialWorkers === null ||
            !canUpdateSocialWorker
          }
          onChange={onChange}
          options={options}
        />
      </PatientAssigneeHorizontalLayout>
      {open && assignee != null && (
        <SocialWorkerChange
          assigned={assignee}
          patientId={patient?.id}
          nextNotification={() => {
            setOpen(false);
            resetProgress();
          }}
        />
      )}
    </>
  );
}

const TabContainer = styled.div<{ isActive: boolean }>`
  border-bottom: ${(props) =>
    props.isActive
      ? border({ width: 2, color: props.theme.palette.primary.main })
      : "none"};
  margin: ${margin(0, 1)};
  white-space: "nowrap";
`;

export function Tabs({
  auctionId,
  auctions,
  goToAuction,
  translations,
}: {
  auctionId: number | undefined;
  auctions: Array<AuctionNoPatient> | undefined;
  goToAuction: ({
    auctionId,
    patientId,
  }: {
    auctionId: number;
    patientId?: number;
  }) => void;
  translations: Translations;
}) {
  const getTabName = (searchType: SearchType) => {
    switch (searchType) {
      case SEARCH_TYPE_CARE:
        return translations.ontologies.patientType.values.care;
      case SEARCH_TYPE_HOSPITAL:
        return translations.ontologies.patientType.values.hospital;
      case SEARCH_TYPE_REHABILITATION:
        return translations.ontologies.patientType.values.rehab;
      case SEARCH_TYPE_TRANSPORT:
        return translations.ontologies.patientType.values.transport;
      case SEARCH_TYPE_MEDICAL_SUPPLIES:
        return translations.ontologies.patientType.values.medicalSupplies;
      case SEARCH_TYPE_HOME_CARE:
        return translations.ontologies.patientType.values.homeCare;
      default:
        return translations.ontologies.patientType.values.care;
    }
  };
  const theme = useTheme();
  return (
    <HorizontalLayout
      aligned
      {...tourAttributes({
        tourKey: PRODUCT_TOURS.parallel_search.key,
        stepKey: PRODUCT_TOURS.parallel_search.steps.old_search_tab.key,
      })}
    >
      {auctions?.map((auction) => {
        const isActive = auction.id === auctionId;
        const hasStatusDot = !!auction.new_responses;

        const tabName = getTabName(auction.search_type);
        const tabTitle = auction.profile?.has_transitional_care
          ? translations.patientForms.transitionalCareForm.tabTitle({
              tabName,
            })
          : tabName;

        return (
          <TabContainer isActive={isActive} key={auction.id}>
            <RSButton
              color={isActive ? "primary" : "grey"}
              id={getTabName(auction.search_type)}
              loading="na"
              onClick={() => goToAuction({ auctionId: auction.id })}
              style={{
                margin: margin(0.25, 1),
                backgroundColor: "transparent",
                fontWeight: isActive ? FONT_WEIGHT_BOLD : undefined,
              }}
              variant="text"
            >
              {hasStatusDot ? (
                <DotContainer>
                  {tabTitle}
                  <DotWithBorder color={theme.palette.secondary.main} />
                </DotContainer>
              ) : (
                tabTitle
              )}
            </RSButton>
          </TabContainer>
        );
      })}
    </HorizontalLayout>
  );
}

function ConnectedPatientMenu({ auction }: { auction: Auction }) {
  const [_, patient] = useGetPatientQuery({
    patientId: auction.patient.id,
    decrypt: true,
  });

  if (!patient) return null;

  return <PatientMenu patient={patient} auction={auction} />;
}

export default function PatientMenu({
  auction,
  patient,
  storybookPosition,
}: {
  auction: Auction;
  patient: PatientType;
  storybookPosition?: CSSProperties["position"];
}) {
  const translations = useTranslations();
  const appNavigation = useCareseekerNavigationHandlers();
  const { height } = useNavbarHeightContext();

  return (
    <PatientMenuContainer
      storybookPosition={storybookPosition}
      navbarHeight={height}
    >
      <InfoStripe>
        <HorizontalLayout aligned margin={margin(0, 0, 0.5, 0)}>
          <PatientIdentifier patient={patient} />
          <ArchivePatient patient={patient} translations={translations} />
        </HorizontalLayout>
        <InfoWrapper>
          <PatientAssignee patient={patient} auction={auction} />
        </InfoWrapper>
      </InfoStripe>
      <TabsContainer justify="flex-start">
        <Tabs
          translations={translations}
          auctions={patient.auctions}
          goToAuction={appNavigation.patient.goToAuction}
          auctionId={auction.id}
        />
        <AddSearchDropDown patient={patient} />
      </TabsContainer>
    </PatientMenuContainer>
  );
}

export const handleSolutionsFromForm = (
  newAuction: Auction,
  auction: Auction,
) => {
  // for transport auctions solutions is a number this is a
  // corner case and needs to be returned to a number array
  let solutions = newAuction?.solutions ?? auction?.solutions ?? null;

  if (typeof solutions === "number") {
    solutions = [solutions];
  }
  return solutions;
};

export const useExtractedPatientFields = (auction: Auction) => {
  const extractedMutable = useMemo(
    () => extractPatientFields(auction),
    [auction],
  );
  const [extracted, setExtracted] = useState(extractedMutable);
  useEffect(() => {
    const changesExist =
      // extractedMutable is pretty shallow so this is fine
      JSON.stringify(extractedMutable) != JSON.stringify(extracted);

    if (changesExist) setExtracted(extractedMutable);
  }, [extractedMutable, extracted]);

  return extracted;
};

export const WatchedFieldContext = createContext<WatchedFieldContextType>({
  watchedFields: null,
  setWatchedFields: () => {},
});

export const useWatchedFields = () => {
  const context = useContext(WatchedFieldContext);

  if (context === undefined) {
    throw new Error(
      "useWatchedFields must be used within a WatchedFieldProvider",
    );
  }

  return context;
};

export function AssessmentPatientMenu({
  auction,
  children,
  patient,
}: {
  auction: Auction;
  children?: React.ReactNode;
  patient: PatientType;
}) {
  const { height } = useNavbarHeightContext();

  const [watchedFields, setWatchedFields] = useState<FieldsHolderType>(() =>
    extractPatientFields(auction),
  );

  useEffect(() => {
    if (patient != null) {
      setWatchedFields(extractPatientFields(auction));
    }
  }, [patient, setWatchedFields]);

  return (
    <WatchedFieldContext.Provider
      value={{
        watchedFields,
        // don't change this line unless you understand
        // the FormWatcher w/ setState in context implications
        setWatchedFields: (fields) => setWatchedFields(cloneDeep(fields)),
      }}
    >
      <EnhancedFormPatientMenu
        auction={auction}
        watchedFields={watchedFields}
      />

      <PatientMenuChildrenWrapper>
        <EmptyBlock navbarHeight={height} />
        {children ? children : <Outlet />}
      </PatientMenuChildrenWrapper>
    </WatchedFieldContext.Provider>
  );
}

function EnhancedPatientMenu({ auction }: { auction: Auction }) {
  const [getSearchCandidates] = useGetStaticSearchCandidates({
    auctionId: auction.id,
  });
  const extracted = useExtractedPatientFields(auction);

  const { setCandidates } = useCandidates();

  useEffect(() => {
    (async () => {
      const newAuction = auction;
      if (
        isValidPatient(newAuction.patient, auction) &&
        shouldDisplayCandidates(auction) &&
        auction.candidates_status === CANDIDATES_STATUS_SUCCESS
      ) {
        const result = await getSearchCandidates();
        if (result?.data) {
          setCandidates({
            candidates: result.data.searchCandidates?.count || 0,
            patientId: auction.patient?.id ?? -1,
          });
        }
      } else {
        setCandidates({
          candidates: undefined,
          patientId: auction.patient?.id ?? -1,
        });
      }
    })();

    // Will rerender when encrypted fields are decrypted
    // This can probably be optimized in the future
  }, [extracted, auction.status, auction.candidates_status]);

  return <ConnectedPatientMenu auction={auction} />;
}

function EnhancedFormPatientMenu({
  auction,
  watchedFields,
}: {
  auction: Auction;
  watchedFields: FieldsHolderType;
}) {
  const [updateSearchCandidates, , , data] = useUpdateSearchCandidates();
  const { setCandidates } = useCandidates();

  const debouncedUpdateSearchCandidates = useCallback(
    debounce((auction: Auction, newAuction: Auction) => {
      updateSearchCandidates({
        ...newAuction,
        start_date: newAuction.start_date ?? auction.start_date,
        id: auction.id,
        solutions: handleSolutionsFromForm(newAuction, auction) ?? [],
        patient: {
          ...newAuction.patient,
          id: auction.patient?.id,
        },
      });
    }, 500),
    [updateSearchCandidates, handleSolutionsFromForm],
  );

  useEffect(() => {
    const newAuction = getAuctionFromFields(watchedFields);
    if (
      isValidPatient(newAuction.patient, auction) &&
      shouldDisplayCandidates(auction)
    ) {
      debouncedUpdateSearchCandidates(auction, newAuction);
    } else {
      setCandidates({
        candidates: undefined,
        patientId: auction.patient?.id ?? -1,
      });
    }
  }, [watchedFields, auction]);

  useEffect(() => {
    if (data) {
      setCandidates({
        candidates: data.searchCandidates?.count || 0,
        patientId: auction.patient?.id ?? -1,
      });
    }
  }, [data]);

  return <ConnectedPatientMenu auction={auction} />;
}

export function PatientMenuStatic({ auction }: { auction: Auction }) {
  const print = usePrint();
  const { height } = useNavbarHeightContext();

  return (
    // We need to handle print this way to avoid refetching / remounting on print
    <>
      {!print && <EnhancedPatientMenu auction={auction} />}
      <PatientMenuChildrenWrapper print={print}>
        {!print && <EmptyBlock navbarHeight={height} />}
        <Outlet />
      </PatientMenuChildrenWrapper>
    </>
  );
}
