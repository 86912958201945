import { AHB_WITHDRAW_REASON } from "core/consts";
import { AuctionProfile, ValueOf } from "core/types";
import {
  Locale as DateFnsLocale,
  format,
  fromUnixTime,
  isValid,
} from "date-fns";
import Translations from "translations/types";

export function formatTimestamp(
  timestamp: number | undefined,
  locale: DateFnsLocale,
): string | undefined {
  if (timestamp === undefined) return undefined;
  const date = fromUnixTime(timestamp);
  return isValid(date) ? format(date, "P", { locale }) : undefined;
}

export function formatSearchLocation(
  searchLocation: AuctionProfile["search_location"] | undefined,
  distanceForZipCode?: boolean,
): string | undefined {
  if (!searchLocation) return undefined;
  return `${searchLocation.zipcode}${
    distanceForZipCode ? ` (${distanceForZipCode})` : ""
  }`;
}

export function getTranslateWithdrawReason(translations: Translations) {
  const reasonToTranslation: Record<
    ValueOf<typeof AHB_WITHDRAW_REASON>,
    string
  > = {
    [AHB_WITHDRAW_REASON.PATIENT_DECLINED_REHAB]:
      translations.insuranceApp.eligibilityTab.sendDocuments.withdrawModal
        .reasonOne,
    [AHB_WITHDRAW_REASON.NOT_REHAB_CAPABLE]:
      translations.insuranceApp.eligibilityTab.sendDocuments.withdrawModal
        .reasonTwo,
    [AHB_WITHDRAW_REASON.NOT_PROMISING]:
      translations.insuranceApp.eligibilityTab.sendDocuments.withdrawModal
        .reasonThree,
    [AHB_WITHDRAW_REASON.PATIENT_DECEASED]:
      translations.insuranceApp.eligibilityTab.sendDocuments.withdrawModal
        .reasonFour,
    [AHB_WITHDRAW_REASON.OTHER]:
      translations.insuranceApp.eligibilityTab.sendDocuments.withdrawModal
        .otherReason,
  };

  return (reason?: ValueOf<typeof AHB_WITHDRAW_REASON>) => {
    if (!reason) {
      console.error("[getTranslateWithdrawReason] reason must be defined");
      return "";
    }
    return reasonToTranslation[reason];
  };
}
